
// @ts-nocheck


export const localeCodes =  [
  "ru",
  "en",
  "de",
  "br",
  "es",
  "fr",
  "it",
  "zh"
]

export const localeLoaders = {
  "ru": [],
  "en": [],
  "de": [],
  "br": [],
  "es": [],
  "fr": [],
  "it": [],
  "zh": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ru",
      "iso": "ru",
      "name": "Русский"
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English"
    },
    {
      "code": "de",
      "iso": "de",
      "name": "Deutsch"
    },
    {
      "code": "br",
      "iso": "pt-BR",
      "name": "Português"
    },
    {
      "code": "es",
      "iso": "es",
      "name": "Español"
    },
    {
      "code": "fr",
      "iso": "fr",
      "name": "Français"
    },
    {
      "code": "it",
      "iso": "it",
      "name": "Italiano"
    },
    {
      "code": "zh",
      "iso": "zh",
      "name": "中文"
    }
  ],
  "defaultLocale": "ru",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://labohemeartistique.ru",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "seo": true,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ru",
    "iso": "ru",
    "name": "Русский",
    "files": []
  },
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "files": []
  },
  {
    "code": "de",
    "iso": "de",
    "name": "Deutsch",
    "files": []
  },
  {
    "code": "br",
    "iso": "pt-BR",
    "name": "Português",
    "files": []
  },
  {
    "code": "es",
    "iso": "es",
    "name": "Español",
    "files": []
  },
  {
    "code": "fr",
    "iso": "fr",
    "name": "Français",
    "files": []
  },
  {
    "code": "it",
    "iso": "it",
    "name": "Italiano",
    "files": []
  },
  {
    "code": "zh",
    "iso": "zh",
    "name": "中文",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
