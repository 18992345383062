import { default as indexhUsMbuXTDgMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/index.vue?macro=true";
import { default as about_45uswk5AMhXX5bMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue?macro=true";
import { default as _91slug_93oQYfK1slXSMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue?macro=true";
import { default as indexYBDIoG57wkMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue?macro=true";
import { default as authorsBknlCZ2oSnMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue?macro=true";
import { default as basketsJ0TNdg7L3Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue?macro=true";
import { default as _91slug_93vgZvfjnAD5Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue?macro=true";
import { default as indexynZJMv0QT4Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue?macro=true";
import { default as genreUAsgYqB3ZnMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue?macro=true";
import { default as indexVDBOQCKbdlMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue?macro=true";
import { default as _91slug_93THPQPZQ6WMMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue?macro=true";
import { default as indexgtWOOW89HFMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue?macro=true";
import { default as materialil9IkjrOZeMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue?macro=true";
import { default as _91slug_93jjxEiNpkSVMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue?macro=true";
import { default as _91slug_935JxV6JVtVbMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue?macro=true";
import { default as indexn5lVXQ9yCKMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue?macro=true";
import { default as stylefF2oWJFtEtMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue?macro=true";
import { default as _91slug_93mwL7lHJ9lKMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue?macro=true";
import { default as index4csd77dHkTMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue?macro=true";
import { default as techniquegbbccJzTsqMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue?macro=true";
import { default as cataloghILrXAvScrMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue?macro=true";
import { default as _91slug_93CuA6u4ybz2Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/cities/[slug].vue?macro=true";
import { default as contactsFD08HhCz22Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue?macro=true";
import { default as _91slug_93xApU2ExAToMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue?macro=true";
import { default as indexTthKJKIFhPMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue?macro=true";
import { default as exhibitionsoWiWowcVKwMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue?macro=true";
import { default as _91slug_93wVyGklcxMHMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue?macro=true";
import { default as indexvevXtHXzBrMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue?macro=true";
import { default as journalhcUH4ij6tVMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue?macro=true";
import { default as _91slug_93PmL3euC6smMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue?macro=true";
import { default as index3BSi1fgS2uMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue?macro=true";
import { default as newsgIwUuWm0d4Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/news.vue?macro=true";
import { default as partnershipwJlJcgNtl1Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue?macro=true";
import { default as privacy_45policywzst8r14q4Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue?macro=true";
import { default as qrQgeGMYPCMnMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue?macro=true";
import { default as searchwuLwNgrFTiMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/search.vue?macro=true";
import { default as _91slug_93dAYAtpGUsNMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue?macro=true";
import { default as indexgmlPBFWZveMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue?macro=true";
import { default as serviceszz6qLlnDAzMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/services.vue?macro=true";
import { default as _91slug_93Y0GvXwaREjMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue?macro=true";
import { default as indexbqi2Y1O4VaMeta } from "/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue?macro=true";
import { default as indexvNulV7WLZ2Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue?macro=true";
import { default as souvenirs3MC2OZHTm0Meta } from "/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue?macro=true";
export default [
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index",
    path: indexhUsMbuXTDgMeta?.path ?? "/",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___ru",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/ru/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___en",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/en/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___de",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/de/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___br",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/br/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___es",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/es/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___fr",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/fr/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___it",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/it/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45uswk5AMhXX5bMeta?.name ?? "about-us___zh",
    path: about_45uswk5AMhXX5bMeta?.path ?? "/zh/about-us",
    meta: about_45uswk5AMhXX5bMeta || {},
    alias: about_45uswk5AMhXX5bMeta?.alias || [],
    redirect: about_45uswk5AMhXX5bMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/ru/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___ru",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___ru",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/en/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___en",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___en",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/de/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___de",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___de",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/br/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___br",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___br",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/es/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___es",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___es",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/fr/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___fr",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___fr",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/it/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___it",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___it",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authorsBknlCZ2oSnMeta?.name ?? undefined,
    path: authorsBknlCZ2oSnMeta?.path ?? "/zh/authors",
    meta: authorsBknlCZ2oSnMeta || {},
    alias: authorsBknlCZ2oSnMeta?.alias || [],
    redirect: authorsBknlCZ2oSnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93oQYfK1slXSMeta?.name ?? "authors-slug___zh",
    path: _91slug_93oQYfK1slXSMeta?.path ?? ":slug()",
    meta: _91slug_93oQYfK1slXSMeta || {},
    alias: _91slug_93oQYfK1slXSMeta?.alias || [],
    redirect: _91slug_93oQYfK1slXSMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYBDIoG57wkMeta?.name ?? "authors___zh",
    path: indexYBDIoG57wkMeta?.path ?? "",
    meta: indexYBDIoG57wkMeta || {},
    alias: indexYBDIoG57wkMeta?.alias || [],
    redirect: indexYBDIoG57wkMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/authors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___ru",
    path: basketsJ0TNdg7L3Meta?.path ?? "/ru/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___en",
    path: basketsJ0TNdg7L3Meta?.path ?? "/en/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___de",
    path: basketsJ0TNdg7L3Meta?.path ?? "/de/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___br",
    path: basketsJ0TNdg7L3Meta?.path ?? "/br/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___es",
    path: basketsJ0TNdg7L3Meta?.path ?? "/es/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___fr",
    path: basketsJ0TNdg7L3Meta?.path ?? "/fr/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___it",
    path: basketsJ0TNdg7L3Meta?.path ?? "/it/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketsJ0TNdg7L3Meta?.name ?? "basket___zh",
    path: basketsJ0TNdg7L3Meta?.path ?? "/zh/basket",
    meta: basketsJ0TNdg7L3Meta || {},
    alias: basketsJ0TNdg7L3Meta?.alias || [],
    redirect: basketsJ0TNdg7L3Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/ru/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___ru",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___ru",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___ru",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___ru",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___ru",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___ru",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___ru",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___ru",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___ru",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___ru",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/en/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___en",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___en",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___en",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___en",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___en",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___en",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___en",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___en",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___en",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___en",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/de/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___de",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___de",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___de",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___de",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___de",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___de",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___de",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___de",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___de",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___de",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/br/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___br",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___br",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___br",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___br",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___br",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___br",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___br",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___br",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___br",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___br",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/es/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___es",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___es",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___es",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___es",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___es",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___es",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___es",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___es",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___es",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___es",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/fr/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___fr",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___fr",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___fr",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___fr",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___fr",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___fr",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___fr",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___fr",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___fr",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___fr",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/it/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___it",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___it",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___it",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___it",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___it",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___it",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___it",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___it",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___it",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___it",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: cataloghILrXAvScrMeta?.name ?? undefined,
    path: cataloghILrXAvScrMeta?.path ?? "/zh/catalog",
    meta: cataloghILrXAvScrMeta || {},
    alias: cataloghILrXAvScrMeta?.alias || [],
    redirect: cataloghILrXAvScrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: genreUAsgYqB3ZnMeta?.name ?? undefined,
    path: genreUAsgYqB3ZnMeta?.path ?? "genre",
    meta: genreUAsgYqB3ZnMeta || {},
    alias: genreUAsgYqB3ZnMeta?.alias || [],
    redirect: genreUAsgYqB3ZnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93vgZvfjnAD5Meta?.name ?? "catalog-genre-slug___zh",
    path: _91slug_93vgZvfjnAD5Meta?.path ?? ":slug()",
    meta: _91slug_93vgZvfjnAD5Meta || {},
    alias: _91slug_93vgZvfjnAD5Meta?.alias || [],
    redirect: _91slug_93vgZvfjnAD5Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexynZJMv0QT4Meta?.name ?? "catalog-genre___zh",
    path: indexynZJMv0QT4Meta?.path ?? "",
    meta: indexynZJMv0QT4Meta || {},
    alias: indexynZJMv0QT4Meta?.alias || [],
    redirect: indexynZJMv0QT4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/genre/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVDBOQCKbdlMeta?.name ?? "catalog___zh",
    path: indexVDBOQCKbdlMeta?.path ?? "",
    meta: indexVDBOQCKbdlMeta || {},
    alias: indexVDBOQCKbdlMeta?.alias || [],
    redirect: indexVDBOQCKbdlMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: materialil9IkjrOZeMeta?.name ?? undefined,
    path: materialil9IkjrOZeMeta?.path ?? "material",
    meta: materialil9IkjrOZeMeta || {},
    alias: materialil9IkjrOZeMeta?.alias || [],
    redirect: materialil9IkjrOZeMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93THPQPZQ6WMMeta?.name ?? "catalog-material-slug___zh",
    path: _91slug_93THPQPZQ6WMMeta?.path ?? ":slug()",
    meta: _91slug_93THPQPZQ6WMMeta || {},
    alias: _91slug_93THPQPZQ6WMMeta?.alias || [],
    redirect: _91slug_93THPQPZQ6WMMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgtWOOW89HFMeta?.name ?? "catalog-material___zh",
    path: indexgtWOOW89HFMeta?.path ?? "",
    meta: indexgtWOOW89HFMeta || {},
    alias: indexgtWOOW89HFMeta?.alias || [],
    redirect: indexgtWOOW89HFMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/material/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93jjxEiNpkSVMeta?.name ?? "catalog-product-slug___zh",
    path: _91slug_93jjxEiNpkSVMeta?.path ?? "product/:slug()",
    meta: _91slug_93jjxEiNpkSVMeta || {},
    alias: _91slug_93jjxEiNpkSVMeta?.alias || [],
    redirect: _91slug_93jjxEiNpkSVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: stylefF2oWJFtEtMeta?.name ?? undefined,
    path: stylefF2oWJFtEtMeta?.path ?? "style",
    meta: stylefF2oWJFtEtMeta || {},
    alias: stylefF2oWJFtEtMeta?.alias || [],
    redirect: stylefF2oWJFtEtMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_935JxV6JVtVbMeta?.name ?? "catalog-style-slug___zh",
    path: _91slug_935JxV6JVtVbMeta?.path ?? ":slug()",
    meta: _91slug_935JxV6JVtVbMeta || {},
    alias: _91slug_935JxV6JVtVbMeta?.alias || [],
    redirect: _91slug_935JxV6JVtVbMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn5lVXQ9yCKMeta?.name ?? "catalog-style___zh",
    path: indexn5lVXQ9yCKMeta?.path ?? "",
    meta: indexn5lVXQ9yCKMeta || {},
    alias: indexn5lVXQ9yCKMeta?.alias || [],
    redirect: indexn5lVXQ9yCKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/style/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: techniquegbbccJzTsqMeta?.name ?? undefined,
    path: techniquegbbccJzTsqMeta?.path ?? "technique",
    meta: techniquegbbccJzTsqMeta || {},
    alias: techniquegbbccJzTsqMeta?.alias || [],
    redirect: techniquegbbccJzTsqMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93mwL7lHJ9lKMeta?.name ?? "catalog-technique-slug___zh",
    path: _91slug_93mwL7lHJ9lKMeta?.path ?? ":slug()",
    meta: _91slug_93mwL7lHJ9lKMeta || {},
    alias: _91slug_93mwL7lHJ9lKMeta?.alias || [],
    redirect: _91slug_93mwL7lHJ9lKMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4csd77dHkTMeta?.name ?? "catalog-technique___zh",
    path: index4csd77dHkTMeta?.path ?? "",
    meta: index4csd77dHkTMeta || {},
    alias: index4csd77dHkTMeta?.alias || [],
    redirect: index4csd77dHkTMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/catalog/technique/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91slug_93CuA6u4ybz2Meta?.name ?? "cities-slug___ru",
    path: _91slug_93CuA6u4ybz2Meta?.path ?? "/ru/cities/:slug()",
    meta: _91slug_93CuA6u4ybz2Meta || {},
    alias: _91slug_93CuA6u4ybz2Meta?.alias || [],
    redirect: _91slug_93CuA6u4ybz2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/cities/[slug].vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___ru",
    path: contactsFD08HhCz22Meta?.path ?? "/ru/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___en",
    path: contactsFD08HhCz22Meta?.path ?? "/en/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___de",
    path: contactsFD08HhCz22Meta?.path ?? "/de/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___br",
    path: contactsFD08HhCz22Meta?.path ?? "/br/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___es",
    path: contactsFD08HhCz22Meta?.path ?? "/es/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___fr",
    path: contactsFD08HhCz22Meta?.path ?? "/fr/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___it",
    path: contactsFD08HhCz22Meta?.path ?? "/it/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsFD08HhCz22Meta?.name ?? "contacts___zh",
    path: contactsFD08HhCz22Meta?.path ?? "/zh/contacts",
    meta: contactsFD08HhCz22Meta || {},
    alias: contactsFD08HhCz22Meta?.alias || [],
    redirect: contactsFD08HhCz22Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/ru/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___ru",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___ru",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/en/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___en",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___en",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/de/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___de",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___de",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/br/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___br",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___br",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/es/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___es",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___es",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/fr/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___fr",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___fr",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/it/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___it",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___it",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exhibitionsoWiWowcVKwMeta?.name ?? undefined,
    path: exhibitionsoWiWowcVKwMeta?.path ?? "/zh/exhibitions",
    meta: exhibitionsoWiWowcVKwMeta || {},
    alias: exhibitionsoWiWowcVKwMeta?.alias || [],
    redirect: exhibitionsoWiWowcVKwMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xApU2ExAToMeta?.name ?? "exhibitions-slug___zh",
    path: _91slug_93xApU2ExAToMeta?.path ?? ":slug()",
    meta: _91slug_93xApU2ExAToMeta || {},
    alias: _91slug_93xApU2ExAToMeta?.alias || [],
    redirect: _91slug_93xApU2ExAToMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTthKJKIFhPMeta?.name ?? "exhibitions___zh",
    path: indexTthKJKIFhPMeta?.path ?? "",
    meta: indexTthKJKIFhPMeta || {},
    alias: indexTthKJKIFhPMeta?.alias || [],
    redirect: indexTthKJKIFhPMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/exhibitions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index",
    path: indexhUsMbuXTDgMeta?.path ?? "/",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___en",
    path: indexhUsMbuXTDgMeta?.path ?? "/en",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___de",
    path: indexhUsMbuXTDgMeta?.path ?? "/de",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___br",
    path: indexhUsMbuXTDgMeta?.path ?? "/br",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___es",
    path: indexhUsMbuXTDgMeta?.path ?? "/es",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___fr",
    path: indexhUsMbuXTDgMeta?.path ?? "/fr",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___it",
    path: indexhUsMbuXTDgMeta?.path ?? "/it",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhUsMbuXTDgMeta?.name ?? "index___zh",
    path: indexhUsMbuXTDgMeta?.path ?? "/zh",
    meta: indexhUsMbuXTDgMeta || {},
    alias: indexhUsMbuXTDgMeta?.alias || [],
    redirect: indexhUsMbuXTDgMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/ru/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___ru",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___ru",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/en/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___en",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___en",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/de/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___de",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___de",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/br/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___br",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___br",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/es/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___es",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___es",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/fr/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___fr",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___fr",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/it/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___it",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___it",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: journalhcUH4ij6tVMeta?.name ?? undefined,
    path: journalhcUH4ij6tVMeta?.path ?? "/zh/journal",
    meta: journalhcUH4ij6tVMeta || {},
    alias: journalhcUH4ij6tVMeta?.alias || [],
    redirect: journalhcUH4ij6tVMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93wVyGklcxMHMeta?.name ?? "journal-slug___zh",
    path: _91slug_93wVyGklcxMHMeta?.path ?? ":slug()",
    meta: _91slug_93wVyGklcxMHMeta || {},
    alias: _91slug_93wVyGklcxMHMeta?.alias || [],
    redirect: _91slug_93wVyGklcxMHMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvevXtHXzBrMeta?.name ?? "journal___zh",
    path: indexvevXtHXzBrMeta?.path ?? "",
    meta: indexvevXtHXzBrMeta || {},
    alias: indexvevXtHXzBrMeta?.alias || [],
    redirect: indexvevXtHXzBrMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/journal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/ru/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___ru",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___ru",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/en/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___en",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___en",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/de/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___de",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___de",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/br/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___br",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___br",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/es/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___es",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___es",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/fr/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___fr",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___fr",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/it/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___it",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___it",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsgIwUuWm0d4Meta?.name ?? undefined,
    path: newsgIwUuWm0d4Meta?.path ?? "/zh/news",
    meta: newsgIwUuWm0d4Meta || {},
    alias: newsgIwUuWm0d4Meta?.alias || [],
    redirect: newsgIwUuWm0d4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PmL3euC6smMeta?.name ?? "news-slug___zh",
    path: _91slug_93PmL3euC6smMeta?.path ?? ":slug()",
    meta: _91slug_93PmL3euC6smMeta || {},
    alias: _91slug_93PmL3euC6smMeta?.alias || [],
    redirect: _91slug_93PmL3euC6smMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3BSi1fgS2uMeta?.name ?? "news___zh",
    path: index3BSi1fgS2uMeta?.path ?? "",
    meta: index3BSi1fgS2uMeta || {},
    alias: index3BSi1fgS2uMeta?.alias || [],
    redirect: index3BSi1fgS2uMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___ru",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/ru/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___en",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/en/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___de",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/de/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___br",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/br/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___es",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/es/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___fr",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/fr/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___it",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/it/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: partnershipwJlJcgNtl1Meta?.name ?? "partnership___zh",
    path: partnershipwJlJcgNtl1Meta?.path ?? "/zh/partnership",
    meta: partnershipwJlJcgNtl1Meta || {},
    alias: partnershipwJlJcgNtl1Meta?.alias || [],
    redirect: partnershipwJlJcgNtl1Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___ru",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___en",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/en/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___de",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/de/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___br",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/br/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___es",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/es/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___fr",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___it",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/it/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policywzst8r14q4Meta?.name ?? "privacy-policy___zh",
    path: privacy_45policywzst8r14q4Meta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policywzst8r14q4Meta || {},
    alias: privacy_45policywzst8r14q4Meta?.alias || [],
    redirect: privacy_45policywzst8r14q4Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___ru",
    path: qrQgeGMYPCMnMeta?.path ?? "/ru/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___en",
    path: qrQgeGMYPCMnMeta?.path ?? "/en/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___de",
    path: qrQgeGMYPCMnMeta?.path ?? "/de/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___br",
    path: qrQgeGMYPCMnMeta?.path ?? "/br/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___es",
    path: qrQgeGMYPCMnMeta?.path ?? "/es/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___fr",
    path: qrQgeGMYPCMnMeta?.path ?? "/fr/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___it",
    path: qrQgeGMYPCMnMeta?.path ?? "/it/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: qrQgeGMYPCMnMeta?.name ?? "qr___zh",
    path: qrQgeGMYPCMnMeta?.path ?? "/zh/qr",
    meta: qrQgeGMYPCMnMeta || {},
    alias: qrQgeGMYPCMnMeta?.alias || [],
    redirect: qrQgeGMYPCMnMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___ru",
    path: searchwuLwNgrFTiMeta?.path ?? "/ru/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___en",
    path: searchwuLwNgrFTiMeta?.path ?? "/en/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___de",
    path: searchwuLwNgrFTiMeta?.path ?? "/de/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___br",
    path: searchwuLwNgrFTiMeta?.path ?? "/br/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___es",
    path: searchwuLwNgrFTiMeta?.path ?? "/es/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___fr",
    path: searchwuLwNgrFTiMeta?.path ?? "/fr/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___it",
    path: searchwuLwNgrFTiMeta?.path ?? "/it/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwuLwNgrFTiMeta?.name ?? "search___zh",
    path: searchwuLwNgrFTiMeta?.path ?? "/zh/search",
    meta: searchwuLwNgrFTiMeta || {},
    alias: searchwuLwNgrFTiMeta?.alias || [],
    redirect: searchwuLwNgrFTiMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/ru/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___ru",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___ru",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/en/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___en",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___en",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/de/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___de",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___de",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/br/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___br",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___br",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/es/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___es",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___es",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/fr/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___fr",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___fr",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/it/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___it",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___it",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: serviceszz6qLlnDAzMeta?.name ?? undefined,
    path: serviceszz6qLlnDAzMeta?.path ?? "/zh/services",
    meta: serviceszz6qLlnDAzMeta || {},
    alias: serviceszz6qLlnDAzMeta?.alias || [],
    redirect: serviceszz6qLlnDAzMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93dAYAtpGUsNMeta?.name ?? "services-slug___zh",
    path: _91slug_93dAYAtpGUsNMeta?.path ?? ":slug()",
    meta: _91slug_93dAYAtpGUsNMeta || {},
    alias: _91slug_93dAYAtpGUsNMeta?.alias || [],
    redirect: _91slug_93dAYAtpGUsNMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgmlPBFWZveMeta?.name ?? "services___zh",
    path: indexgmlPBFWZveMeta?.path ?? "",
    meta: indexgmlPBFWZveMeta || {},
    alias: indexgmlPBFWZveMeta?.alias || [],
    redirect: indexgmlPBFWZveMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/services/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/ru/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___ru",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___ru",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___ru",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/en/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___en",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___en",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___en",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/de/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___de",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___de",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___de",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/br/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___br",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___br",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___br",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/es/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___es",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___es",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___es",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/fr/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___fr",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___fr",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___fr",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/it/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___it",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___it",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___it",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: souvenirs3MC2OZHTm0Meta?.name ?? undefined,
    path: souvenirs3MC2OZHTm0Meta?.path ?? "/zh/souvenirs",
    meta: souvenirs3MC2OZHTm0Meta || {},
    alias: souvenirs3MC2OZHTm0Meta?.alias || [],
    redirect: souvenirs3MC2OZHTm0Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Y0GvXwaREjMeta?.name ?? "souvenirs-categoryslug-slug___zh",
    path: _91slug_93Y0GvXwaREjMeta?.path ?? ":categoryslug()/:slug()",
    meta: _91slug_93Y0GvXwaREjMeta || {},
    alias: _91slug_93Y0GvXwaREjMeta?.alias || [],
    redirect: _91slug_93Y0GvXwaREjMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbqi2Y1O4VaMeta?.name ?? "souvenirs-categoryslug___zh",
    path: indexbqi2Y1O4VaMeta?.path ?? ":categoryslug()",
    meta: indexbqi2Y1O4VaMeta || {},
    alias: indexbqi2Y1O4VaMeta?.alias || [],
    redirect: indexbqi2Y1O4VaMeta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/[category-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNulV7WLZ2Meta?.name ?? "souvenirs___zh",
    path: indexvNulV7WLZ2Meta?.path ?? "",
    meta: indexvNulV7WLZ2Meta || {},
    alias: indexvNulV7WLZ2Meta?.alias || [],
    redirect: indexvNulV7WLZ2Meta?.redirect,
    component: () => import("/home/runner/work/lab-frontend/lab-frontend/pages/souvenirs/index.vue").then(m => m.default || m)
  }
]
  }
]