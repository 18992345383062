<template>
  <NuxtLoadingIndicator
    :throttle=50
    :height=4
    color="repeating-linear-gradient(to right,#111827 0%,#1f2937 100%)"
  />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
