import revive_payload_client_4sVQNw7RlN from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/lab-frontend/lab-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/lab-frontend/lab-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import nuxt_plugin_03YjkxYbK5 from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/lab-frontend/lab-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import strapi_CICqBBANNd from "/home/runner/work/lab-frontend/lab-frontend/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/lab-frontend/lab-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_masonry_wall_13R6vu58Yn from "/home/runner/work/lab-frontend/lab-frontend/plugins/vue-masonry-wall.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  nuxt_plugin_03YjkxYbK5,
  i18n_yfWm7jX06p,
  strapi_CICqBBANNd,
  chunk_reload_client_UciE0i6zes,
  vue_masonry_wall_13R6vu58Yn
]