export const isProd = process.env.NODE_ENV === "production";
export const apiHost = isProd
  ? "https://api.labohemeartistique.ru"
  : "https://api.labohemeartistique.ru";
export const baseHost = isProd
  ? "https://labohemeartistique.ru"
  : "http://localhost:3000";

export const imgHost = isProd ? baseHost : apiHost;

export const apiUrl = `${apiHost}`;
