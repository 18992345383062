<script setup lang="ts">
import { navigateTo, useHead, useStrapi } from "@/.nuxt/imports";
import { isProd } from "./constants/constants";
import { useRoute } from "vue-router";
import type { CityT } from "./schemas/schemas";
import { getBySlug } from "@/composables/strapi-actions";
import { useI18n } from "@/.nuxt/imports";

const { locale } = useI18n();
const { find } = useStrapi();

const route = useRoute();

defineProps({
  error: Object,
});

useHead({
  title: "Страница не найдена",
  meta: [{ name: "description", content: "" }],
});

if (!process.client) {
  const splitedPath = route.path.split("/");
  const slug = splitedPath[splitedPath.length - 1];
  let isCatalogRedirect = false;

  if (splitedPath.includes("catalog") && !splitedPath.includes("product")) {
    const res = await getBySlug(slug, "catalogs", locale.value);

    if (res.data[0]) {
      isCatalogRedirect = true;
      await navigateTo(`ru/catalog/product/${slug}`, { redirectCode: 301 });
    }
  }

  if (slug && slug.length > 0 && !isCatalogRedirect) {
    const data = await find<CityT>("cities", {
      filters: {
        slug: {
          $eq: slug,
        },
      },
    });

    const city = data.data[0];

    if (city) {
      navigateTo(`ru/cities/${slug}`, { redirectCode: 301 });
    }
  }
}

// TODO Настроить страницу ошибок (404, 500)
</script>

<template>
  <NuxtLayout>
    <div class="mx-auto max-w-screen-2xl">
      <h2 v-if="isProd">Страница не найдена</h2>
      <pre v-if="!isProd">
        {{ error }}
      </pre>
    </div>
  </NuxtLayout>
</template>
